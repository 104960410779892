import React from "react"
import Layout from "../components/Layout"

const Contact = () => {
  return (
    <Layout>
      <main className="page">
        <div className="contact-page">
          <h1>Contact Us</h1>
          <h3>
            Beard Law Company is located at 101 North Allegheny Street,
            Bellefonte, PA 16823
          </h3>
          <h3>Phone number: (814) 548-0028</h3>
          <h3>Email: info@beardlawco.com</h3>
          {/* <article className="contact-info">
            <h3>Contact Us</h3>
            <p>
              Beard Law Company is located at 101 North Allegheny Street,
              Bellefonte, PA 16823
            </p>
            <p>Phone number: (814) 548-0028</p>
            <p>Email: info@beardlawco.com</p>
          </article> */}
          {/* <article>
            <form className="form contact-form">
              <div className="form-row">
                <label htmlFor="name">Your Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="form-row">
                <label htmlFor="email">Your Email</label>
                <input type="text" name="email" id="email" />
              </div>
              <div className="form-row">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message"></textarea>
              </div>
              <button type="submit" className="btn block">
                Submit
              </button>
            </form>
          </article> */}
        </div>
      </main>
    </Layout>
  )
}

export default Contact
